import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const ChatProp = ({
  activePrompt,
  handlePromptClick,
  hiddenPrompts,
  trueItems,
  predefinedPrompts,
  handleCurrActivePrompt,
  currActivePrompt,
}) => {
  const promptList = useSelector((state) => state.todo.promptList);

  useEffect(() => {
    if (activePrompt?.id) {
      handleCurrActivePrompt(activePrompt?.id);
    }
  }, [activePrompt]);

  return (
    <ul className={"cht_prop"}>
      {(promptList?.length === 5 || promptList?.length === 6) &&
      !activePrompt?.id &&
      currActivePrompt === "" ? (
        <li className="cht_prop_itm">Please Click below on Generate Button</li>
      ) : (
        (() => {
          const items = [];
          for (let index = 0; index < predefinedPrompts.length; index++) {
            const prompt = predefinedPrompts[index];
            const isHidden = hiddenPrompts[index];
            const isLastTrueItem =
              trueItems?.length > 0
                ? trueItems.length + 1 === index + 1
                : index === trueItems?.length;
            const itemClasses = `cht_prop_itm ${isHidden ? "disabled" : ""} ${
              isLastTrueItem && currActivePrompt < 4
                ? "enable"
                : currActivePrompt === 4
                ? "enable"
                : ""
            }`;

            items.push(
              <li
                key={prompt?.id}
                className={itemClasses}
                onClick={() => handlePromptClick(index)}
              >
                <h6>{prompt?.key}</h6>
                <p>{prompt?.title}</p>
                {index === 4 && <div className="orRubric">OR</div>}
              </li>
            );
          }
          return items;
        })()
      )}
    </ul>
  );
};

export default ChatProp;
