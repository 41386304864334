import React, { useEffect, useState } from "react";
import { images } from "../../constants/ImageImports";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { mdActions } from "../../redux/slices/modalSlice";
import { MultipleSelect } from "../../components/common/formInputs/MultiSelect";
import usePost from "../../services/customHook/usePost";
import { APIConstant } from "../../services/apiConstant";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";
import { todoListAction } from "../../redux/slices/todoSlice";
import DatePicker from "../../components/common/DatePicker";
import {
  voiceRefreshAction,
  voiceToneAction,
  voiceTypeAction,
  voicelanguageAction,
} from "../../redux/slices/voiceModalSlice";

const NotFound = ({ data, selectedClass, loading }) => {
  return (
    <>
      {data?.length === 0 && selectedClass === "" && !loading ? (
        <small
          className="error_text"
          style={{ position: "relative", marginLeft: "13px" }}
        >
          No student found in this class
        </small>
      ) : (
        <small
          className="error_text"
          style={{ position: "relative", visibility: "hidden" }}
        >
          No student found in this class
        </small>
      )}{" "}
    </>
  );
};

const ACTIONS = [
  { icon: images?.tableEdit, key: "EDIT" },
  {
    icon: images?.tableDelete,
    key: "DELETE",
  },
];

export const CreateToDo = () => {
  const dispatch = useDispatch();
  const { mutateAsync } = usePost();
  const [todoList, setTodoList] = useState(
    useSelector((state) => state.todo.todoList)
  );
  const currentUserId = useSelector(
    (state) => state?.profile?.data?.payload?.data?._id
  );
  const isVoiceRefresh = useSelector((state) => state.voiceModal.isReferesh);
  const AItone = useSelector((state) => state.voiceModal.isTone);
  const AItype = useSelector((state) => state.voiceModal.type);
  const AIlanguage = useSelector((state) => state.voiceModal.isLanguage);
  const [classList, setClassList] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selecteStudent, setSelectedStudent] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState("");

  useEffect(() => {
    if (todoList?.length > 0) {
      setSelectedClass(todoList?.[0]?.class_id);
      setSelectedDate(todoList?.[0]?.task_start_date);
      setEndDate(todoList?.[0]?.task_end_date);
      setSelectedStudent(todoList?.[0]?.student_id);
    }
  }, [todoList]);

  const handleClassListPost = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.STUDENT_CLASS_LIST,
        payload: {},
        isToastDiasble: true,
      });
      setClassList(payload?.data);
    } catch (error) {}
  };

  const handleStudetListPost = async () => {
    try {
      setLoading(true);
      const { payload } = await mutateAsync({
        url: APIConstant?.CLASS_STUDENT_LIST,
        payload: {
          id: selectedClass,
          page: 1,
          search: "",
          pageSize: 100,
        },
        isToastDiasble: true,
      });
      setStudentList(payload?.data?.result);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const formReset = () => {
    setSelectedClass("");
    setStudentList([]);
    setSelectedStudent([]);
    setSelectedDate("");
    setTitle("");
    setEndDate("");
    setDescription("");
    dispatch(voiceRefreshAction(false));
    dispatch(voiceToneAction(""));
    dispatch(voicelanguageAction("english"));
    dispatch(voiceTypeAction("short"));
  };
  const halfFormReset = () => {
    setTitle("");
    setDescription("");
    dispatch(voiceRefreshAction(false));
    dispatch(voiceToneAction(""));
    dispatch(voicelanguageAction("english"));
    dispatch(voiceTypeAction("short"));
  };

  useEffect(() => {
    handleClassListPost();
  }, []);

  useEffect(() => {
    if (selectedClass !== "") {
      handleStudetListPost();
    }
  }, [selectedClass, isEdit]);

  const onClassChange = (event) => {
    setSelectedClass(event?.target?.value);
    setStudentList([]);
    setSelectedStudent([]);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event?.target?.value);
    setEndDate(
      moment(event?.target?.value).add(6, "days").format("YYYY-MM-DD")
    );
  };

  const onVoiceHandler = () => {
    if (description === "") {
      toast.error("Please enter some text into descriptions");
    } else {
      dispatch(mdActions.showModal(MODAL_TYPE?.VOICE_TONE));
    }
  };

  const generateAIText = async () => {
    const apiKey = "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";
    const modelName = "gpt-3.5-turbo-instruct";
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/engines/" + modelName + "/completions",
        {
          prompt: `Please create a description in ${AIlanguage} for ${description} in a ${AItype} length and in a  ${AItone} tone of voice`,
          max_tokens: 3700,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const aiText = response?.data?.choices[0].text.replace(/^\n\n/, "");
      setDescription(aiText);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 429) {
        toast.error(
          "Open AI API key Limit is Reached. Please get in touch with Website Admin"
        );
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (description) {
      generateAIText();
    }
  }, [isVoiceRefresh]);

  const addTaskHandler = () => {
    if (title?.length < 10) {
      toast.error("Please Enter title greater than 10");
    } else if (description?.length < 10) {
      toast.error("Please Enter description greater than 10");
    } else {
      let payLoad = {
        addedBy: currentUserId,
        id: new Date().getTime(),
        class_id: selectedClass,
        student_id: selecteStudent,
        task_title: title,
        task_content: description,
        task_start_date: selectedDate,
        task_end_date: endDate,
      };
      try {
        handleTodoListPost({ ...payLoad });
      } catch (error) {}
    }
  };

  const validForm = () => {
    if (
      selectedClass === "" ||
      selecteStudent?.length === 0 ||
      endDate === "" ||
      selectedDate === "" ||
      title === "" ||
      description === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleTodoListPost = (data) => {
    if (data) {
      const updatedTasks = todoList.map((task) => ({
        ...task,
        task_start_date: data?.task_start_date,
        task_end_date: data?.task_end_date,
      }));
      let updatedTaskList;
      if (isEdit) {
        updatedTaskList = updatedTasks.map((task) =>
          task.id === isEdit.id ? { ...data } : task
        );
        setIsEdit("");
        halfFormReset();
        toast.success("Task updated successfully.");
      } else {
        updatedTaskList = [
          ...updatedTasks,
          {
            ...data,
            task_start_date: data?.task_start_date,
            task_end_date: data?.task_end_date,
          },
        ];
        toast.success("Task added successfully.");
        halfFormReset();
      }
      dispatch(todoListAction(updatedTaskList));
      setTodoList(updatedTaskList);
    }
  };

  const onActionHanlder = async (item, identifier) => {
    if (identifier === "EDIT") {
      let editclassName = classList.find(
        (data) => data?._id === item?.class_id
      );
      setSelectedClass(editclassName?._id);
      await handleStudetListPost();
      setIsEdit(item);
      setDescription(item?.task_content);
      setTitle(item?.task_title);
      setSelectedDate(item?.task_start_date);
      setEndDate(item?.task_end_date);
      setSelectedStudent(item?.student_id);
    } else {
      const updatedTasks = todoList.filter(
        (task) => task?.task_title !== item?.task_title
      );
      dispatch(todoListAction(updatedTasks));
      setTodoList(updatedTasks);
      formReset();
      toast.success("Task deleted successfully.");
    }
  };

  const assignmentModalHandler = () => {
    dispatch(mdActions.showModal(MODAL_TYPE?.ASSIGNMENT));
  };

  return (
    <div className="create_todo_page sec-padding ">
      <div className="cstm_Ctnr">
        <div className="cmn_hdr">
          <h3>
            <b>Create New To Do List</b>
          </h3>
        </div>
        <div className="table_wpr p-50 ">
          <div className="create_todo_wpr mb-30">
            <div className="create_todo_lft create_form">
              <div className="form_row item2">
                <div className="input_wpr custom-select-container">
                  <select
                    className="select_inpt"
                    onChange={onClassChange}
                    value={selectedClass}
                  >
                    <option value="" disabled selected>
                      Select a class
                    </option>
                    {classList?.map((item, i) => {
                      return (
                        <option key={i} value={item?._id}>
                          {item?.class_title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input_wpr w-48">
                  <div>
                    <MultipleSelect
                      placeholder={"Select Students"}
                      val={selecteStudent}
                      setAction={setSelectedStudent}
                      data={studentList}
                    />
                    <NotFound
                      loading={loading}
                      data={studentList}
                      selectedClass={selectedClass}
                    />
                  </div>
                </div>
                <DatePicker
                  id={"start-date"}
                  placeholder="Select Start Date"
                  handleDateChange={handleDateChange}
                  selectedDate={selectedDate}
                  isMinDate
                  minDate={moment().format("YYYY-MM-DD")}
                />
                <DatePicker
                  id={"end-date"}
                  placeholder="End Date"
                  disabled={true}
                  handleDateChange={() => {}}
                  selectedDate={endDate}
                  isMinDate
                  minDate={moment().format("YYYY-MM-DD")}
                />
                <div className="voice_gen">
                  <div className="input_wpr w-100 input_task">
                    <input
                      name="tasktitle"
                      type="text"
                      placeholder="Enter Task Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>

                <div className="voice_gen">
                  <div className="input_wpr text_area">
                    {loading ? (
                      <Skeleton
                        animation="wave"
                        className="voice_Skel"
                        width={"100%"}
                      />
                    ) : (
                      <textarea
                        name="description"
                        type="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter Description"
                      ></textarea>
                    )}
                  </div>
                  <span onClick={onVoiceHandler}>
                    <img src={images?.generateVoice} alt="AI_VOICE" />
                  </span>
                </div>
                <div className="btn-group justify-space-between mt-40">
                  <button
                    disabled={validForm()}
                    className="btn_reset pink_btn"
                    onClick={addTaskHandler}
                  >
                    {isEdit ? "Save" : "Add Task"}
                  </button>
                  {todoList?.length > 0 && !isEdit && (
                    <button
                      type="button"
                      className="btn_reset pink_btn"
                      onClick={assignmentModalHandler}
                    >
                      Add Assignment
                    </button>
                  )}
                </div>
              </div>
              <div className="cmn_hdr mt-40">
                <h3>
                  <b>Recent Created</b>
                </h3>
              </div>
              <ul className="recent_list mt-30 mb-30">
                {todoList?.length > 0 ? (
                  todoList
                    ?.filter(
                      (obj, index, arr1) => obj.addedBy === currentUserId
                    )
                    .map((item, i, arr) => {
                      return (
                        <li
                          key={`${item?._id}-${i}`}
                          style={{ wordBreak: "break-word" }}
                        >
                          <span className="icon">
                            <img src={images?.stepperCheck} alt="check-icon" />
                          </span>
                          <span className="title">{item?.task_title}</span>
                          <ul className="action_btn_wpr">
                            {ACTIONS.map((obj, i, arr) => {
                              return (
                                <li
                                  onClick={() =>
                                    onActionHanlder(item, obj?.key)
                                  }
                                >
                                  <img
                                    className="prft_img circle transition"
                                    src={obj?.icon}
                                    alt={obj?.key}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    })
                ) : (
                  <p className="empty">No To Do Found </p>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
