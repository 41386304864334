import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../constants/ImageImports";
import { SelectBox } from "../../components/common/formInputs/SelectBox";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { mdActions } from "../../redux/slices/modalSlice";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import ChatProp from "./ChatProp";
import PdfGenerator from "../../components/common/pdf/PdfGenerator";
import { toast } from "react-toastify";
import {
  pdfDataAction,
  assignmentDataAction,
  pdfStartAction,
  promptListAction,
  todoListAction,
} from "../../redux/slices/todoSlice";
import { Link } from "react-router-dom";
import DatePicker from "../../components/common/DatePicker";
import moment from "moment";

export const CreateToDoAssignment = () => {
  const dispatch = useDispatch();
  const todoListRedx = useSelector((state) => state.todo.todoList);
  const assignmentData = useSelector((state) => state.todo.assignmentData);
  const isPdfStart = useSelector((state) => state.todo.isPdfStart);
  const promptList = useSelector((state) => state.todo.promptList);
  const pdfData = useSelector((state) => state.todo.pdfData);

  const isRefresh = useSelector((state) => state.voiceModal.isReferesh);
  const tone = useSelector((state) => state.voiceModal.isTone);
  const isVoiceRefresh = useSelector((state) => state.voiceModal.isReferesh);
  const AItype = useSelector((state) => state.voiceModal.type);
  const AIlanguage = useSelector((state) => state.voiceModal.isLanguage);
  const type = useSelector((state) => state.voiceModal.type);
  const language = useSelector((state) => state.voiceModal.isLanguage);
  const [message, setMessage] = useState("");
  const [todoList, setTodoList] = useState(todoListRedx);
  // const [title, setTitle] = useState(assignmentData?.title || "");
  const [textareaValue, setTextareaValue] = useState(
    assignmentData?.textareaValue || ""
  );
  const [selectAssignment, setSelectAssignment] = useState(
    assignmentData?.selectAssignment || ""
  );
  const [selectProduct, setSelectProduct] = useState(
    assignmentData?.selectProduct || ""
  );

  const [showPrompt, setShowPrompt] = useState(true);
  const [activePrompt, setActivePrompt] = useState({});
  const [gptData, setGptData] = useState();
  const [trueItems, setTrueItems] = useState([]);
  const [isTextLoader, setIsTextLoader] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currActivePrompt, setCurrActivePrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [voiceTone, setVoiceTone] = useState("");
  const [hiddenPrompts, setHiddenPrompts] = useState(() => {
    return promptList ? promptList : Array(8).fill(false);
  });

  useEffect(() => {
    if (todoList.length > 0) {
      setSelectedDate(todoList?.[0]?.task_start_date);
      setEndDate(todoList?.[0]?.task_end_date);
    }
  }, [todoList]);

  let predefinedPrompts = [
    {
      id: 1,
      key: "Instructions",
      title: textareaValue,
      preText: "Create simple instructions for",
      postText: "in points in html ul list format.",
    },
    {
      id: 2,
      key: "Learning Objectives",
      title: textareaValue,
      preText: "In one sentence what are the learning objectives of",
      postText: ", please give the content into points in html format?",
    },
    {
      id: 3,
      key: "Success Criteria",
      title: textareaValue,
      preText: "What is the success criteria for",
      postText: ", show this in at least 2-3 points in html format?",
    },
    {
      id: 4,
      key: "Curriculum expectations",
      title: textareaValue,
      preText:
        "What are the overall curriculum expectations with respect to the",
      postText:
        "from the most recent Ontario Curriculum document, show at least 5 points in html ul list format?",
    },
    {
      id: 5,
      key: "Rubric",
      title: `Option 1: Create a rubric in a table using the categories Knowledge / Understanding, Thinking / Inquiry, Communication and Application as the criteria vertically and levels 1 through 4 horizontally.`,
      preText: "",
      postText:
        "please keep categories at horizontal and level are on vertically side in a tabular form in html format",
    },
    {
      id: 6,
      key: "Rubric",
      title: `Option 2: Create a rubric in a table using the specific expectations from from the most recent Ontario curriculum documents as the criteria vertically and levels 1 through 4 horizontally i a tabular form in html format.`,
      preText: "",
      postText: "",
    },
  ];

  const voiceHandler = (message) => {
    setVoiceTone(message);
    dispatch(mdActions.showModal(MODAL_TYPE?.VOICE_TONE));
  };

  /* Todo List */
  const deleteHandler = (itemData) => {
    if (itemData) {
      const updatedTasks = todoList.filter(
        (task) => task?.task_title !== itemData?.task_title
      );
      dispatch(todoListAction(updatedTasks));
      setTodoList(updatedTasks);
    }
  };

  const handlePromptClick = (index) => {
    let indexNumber =
      trueItems?.length > 0
        ? currActivePrompt === 4 || index + 1 === trueItems?.length + 1
        : index === trueItems?.length;

    if (indexNumber) {
      setHiddenPrompts((prevHiddenPrompts) => {
        let updatedHiddenPrompts = [...prevHiddenPrompts];
        updatedHiddenPrompts[index] = true;
        dispatch(promptListAction(updatedHiddenPrompts));
        const trueList = updatedHiddenPrompts.filter((item, index) => item);
        setTrueItems(trueList);
        return updatedHiddenPrompts;
      });
      let data = "";

      if (index === 4) {
        data = {
          ...predefinedPrompts[index],
          title: predefinedPrompts[index]?.title.replace("Option 1: ", ""),
        };
      } else if (index === 5) {
        data = {
          ...predefinedPrompts[index],
          title: predefinedPrompts[index]?.title.replace("Option 2: ", ""),
        };
      } else {
        data = predefinedPrompts[index];
      }

      setActivePrompt(data);
      setMessage(`${data?.preText} ${data?.title} ${data?.postText}`);
    }
  };

  useEffect(() => {
    const trueList = hiddenPrompts.filter((item) => item);
    setTrueItems(trueList);
  }, [hiddenPrompts]);

  useEffect(() => {
    if (textareaValue && selectAssignment && selectProduct) {
      let pdfDetail = {
        title: textareaValue,
        textareaValue: textareaValue,
        selectAssignment: selectAssignment,
        selectProduct: selectProduct,
      };
      dispatch(assignmentDataAction(pdfDetail));
    }
  }, [textareaValue, selectAssignment, selectProduct]);

  const addAssHandler = () => {
    if (activePrompt) {
      const index = trueItems.length > 0 ? trueItems.length - 1 : 0;
      const newAssignment = {
        key: predefinedPrompts[index].key,
        value: gptData,
      };

      const updatedAssignments = [...pdfData, newAssignment];
      dispatch(pdfDataAction(updatedAssignments));

      setShowPrompt(true);
      setGptData();
      setActivePrompt({});
    }
  };

  /* ChatGPT */
  const sendMessage = async () => {
    setShowPrompt(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const apiKey = "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";
    const modelName = "gpt-3.5-turbo-instruct";
    setIsTextLoader(true);
    setMessage("");
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/engines/" + modelName + "/completions",
        {
          prompt: `${activePrompt?.preText} ${activePrompt?.title} ${activePrompt?.postText}`,
          max_tokens: 3700,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      setGptData(response?.data?.choices[0].text);
      setIsTextLoader(false);
      if (currActivePrompt === 5 || currActivePrompt === 6) {
        setCurrActivePrompt("");
      }
    } catch (error) {}
  };

  const generateAIText = async () => {
    const apiKey = "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";
    const modelName = "gpt-3.5-turbo-instruct";
    setIsTextLoader(true);
    if (!isTextLoader) {
      setLoading(true);
    }

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/engines/" + modelName + "/completions",
        {
          prompt: `Please create a description in ${AIlanguage} for ${
            voiceTone ? voiceTone : textareaValue
          } in a ${AItype} length and in a  ${tone} tone of voice`,
          max_tokens: 3700,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const aiText = response?.data?.choices[0].text.replace(/^\n\n/, "");
      if (voiceTone) {
        setGptData(aiText);
      } else {
        setTextareaValue(aiText);
      }
      setLoading(false);
      setIsTextLoader(false);
    } catch (error) {
      if (error?.response?.status === 429) {
        toast.error(
          "Open AI API key Limit is Reached. Please get in touch with Website Admin"
        );
      }
      setLoading(false);
      setIsTextLoader(false);
    }
  };

  useEffect(() => {
    if ((tone || language) && activePrompt?.prompt) {
      sendMessage();
    }
  }, [isRefresh]);

  useEffect(() => {
    if (tone) {
      generateAIText();
    }
  }, [isVoiceRefresh]);

  const ChatResponse = () => {
    return (
      <div className="chat_resp">
        <div className="scrl_inr">
          {isTextLoader ? (
            <Skeleton
              animation="wave"
              className="voice_Skel mb-20"
              width={"100%"}
              height={"46vh"}
            />
          ) : (
            <div
              className="chat_resp_itm chat_itm_cvr"
              dangerouslySetInnerHTML={{ __html: gptData }}
            ></div>
          )}
          {gptData && (
            <div className="chat_resp_itm chat_itm_cvr">
              <ul className="reg_btns">
                <li onClick={addAssHandler}>
                  <img src={images?.reg1} alt="img" />
                  Add to assignment
                </li>
                <li
                  onClick={() => {
                    setGptData();
                    sendMessage();
                  }}
                >
                  <img src={images?.reg2} alt="img" />
                  Regenerate
                </li>

                <li onClick={() => voiceHandler(gptData)}>
                  <img src={images?.reg3} alt="img" />
                  Voice Tone
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  };

  const msgHandler = (e) => {
    setMessage(e.target.value);
    setActivePrompt((prev) => ({
      ...prev,
      prompt: e.target.value,
    }));
  };

  const resetPdfhandler = () => {
    dispatch(pdfStartAction(false));
    dispatch(promptListAction([]));
    dispatch(pdfDataAction([]));
    setGptData();
    setCurrActivePrompt("");
    setHiddenPrompts([]);
    setActivePrompt(null);

    // window.location.reload();
  };

  const generatePdfHandler = async () => {
    setShowPdf(true);
  };

  const onVoiceHandler = () => {
    if (textareaValue === "") {
      toast.error("Please enter some text into descriptions");
    } else {
      dispatch(mdActions.showModal(MODAL_TYPE?.VOICE_TONE));
    }
  };

  return (
    <div className="create_todo_page sec-padding ">
      <div className="cstm_Ctnr">
        <div className="cmn_hdr">
          <h3>
            {showPdf ? <b>PDF View Version</b> : <b>Create New To Do List</b>}
          </h3>
        </div>
        <div className={showPdf ? "table_wpr" : "table_wpr p-0 "}>
          {showPdf ? (
            <PdfGenerator
              data={pdfData}
              tasks={todoList}
              assStartDate={selectedDate}
              assEndDate={endDate}
            />
          ) : (
            <div className="create_todo_wpr">
              <div
                className="create_todo_lft create_form p-30"
                style={{ width: "500px" }}
              >
                <form className={isPdfStart ? "disable_form pe-none" : ""}>
                  <div className="form_row item2 mt-0">
                    <DatePicker
                      id={"start-date"}
                      placeholder="Start Date"
                      handleDateChange={() => {}}
                      disabled={true}
                      selectedDate={selectedDate}
                      isMinDate
                      minDate={moment().format("YYYY-MM-DD")}
                    />
                    <DatePicker
                      id={"end-date"}
                      placeholder="End Date"
                      disabled={true}
                      handleDateChange={() => {}}
                      selectedDate={endDate}
                      isMinDate
                      minDate={moment().format("YYYY-MM-DD")}
                    />
                    <div
                      className="input_wpr text_area"
                      style={{ position: "relative" }}
                    >
                      {loading ? (
                        <Skeleton
                          animation="wave"
                          className="voice_Skel"
                          width={"100%"}
                        />
                      ) : (
                        <>
                          <textarea
                            name="description"
                            type="textarea"
                            value={textareaValue}
                            onChange={(e) => setTextareaValue(e.target.value)}
                            placeholder="Enter Description"
                          ></textarea>
                          <span
                            style={{ position: "absolute", right: "-27px" }}
                            onClick={onVoiceHandler}
                          >
                            <img src={images?.generateVoice} alt="AI_VOICE" />
                          </span>
                        </>
                      )}
                    </div>

                    <div className="full text-dark">
                      <SelectBox
                        className={"input_wpr"}
                        label={"Assessment Type"}
                        value={selectAssignment}
                        data={["For", "As For", "Of Learning"]}
                        setValue={setSelectAssignment}
                        placeholder={"Select Assessment Type"}
                      />
                    </div>
                    <div className="full text-dark">
                      <SelectBox
                        className={"input_wpr"}
                        label={"Product Type"}
                        value={selectProduct}
                        data={["Product", "Observation or ", "Discussion"]}
                        setValue={setSelectProduct}
                        placeholder={"Select Product Type"}
                      />
                    </div>
                  </div>
                </form>
                {isPdfStart && (
                  <small className="error_text">
                    <b>
                      If you want to edit form you need to Click on Reset Button
                    </b>
                  </small>
                )}

                {/* Recent List */}
                <div className="cmn_hdr mt-40">
                  <h3>
                    <b>Recent Created Task</b>
                  </h3>
                </div>

                <ul className="recent_list mt-30 ">
                  {todoList?.length > 0 ? (
                    todoList?.map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="icon">
                            <img src={images?.stepperCheck} alt="img" />
                          </span>
                          <span className="title">{item?.task_title}</span>
                          <ul className="action_btn_wpr">
                            <li onClick={() => deleteHandler(item)}>
                              <img
                                className="prft_img circle transition"
                                src={images?.tableDelete}
                                alt="img"
                              />
                            </li>
                          </ul>
                        </li>
                      );
                    })
                  ) : (
                    <p className="empty">No To Do Found </p>
                  )}
                </ul>

                <div className="btn-group justify-end">
                  {!isPdfStart && (
                    <button
                      className="btn_reset pink_btn mt-40"
                      disabled={Object.keys(assignmentData).length === 0}
                      onClick={() => {
                        // if (title.length < 10) {
                        //   toast.error("Please Enter title greater than 10")
                        // }
                        if (textareaValue?.length < 10) {
                          toast.error(
                            "Please Enter description greater than 10"
                          );
                        } else {
                          dispatch(pdfStartAction(true));
                        }
                      }}
                    >
                      Start Pdf
                    </button>
                  )}
                  {Object.keys(assignmentData).length > 0 && isPdfStart && (
                    <button
                      className="btn_reset pink_btn mt-40"
                      onClick={resetPdfhandler}
                    >
                      Reset
                    </button>
                  )}
                  {pdfData?.length === 5 && (
                    <button
                      className="btn_reset pink_btn mt-40"
                      onClick={generatePdfHandler}
                    >
                      Generate PDF
                    </button>
                  )}
                  {activePrompt?.prompt ? (
                    ""
                  ) : (
                    <Link
                      to={"/create-todo"}
                      className="btn_reset pink_btn mt-40"
                    >
                      Back
                    </Link>
                  )}
                </div>
              </div>
              <div
                className={
                  isPdfStart
                    ? "create_todo_rgt p-0"
                    : "create_todo_rgt p-0 no-pdf"
                }
                style={{ width: "505px" }}
              >
                <div className="chat_wpr">
                  <h3 className="cht_hdr mb-20">How can i help you?</h3>
                  {showPrompt ? (
                    <ChatProp
                      activePrompt={activePrompt}
                      handlePromptClick={handlePromptClick}
                      hiddenPrompts={hiddenPrompts}
                      trueItems={trueItems}
                      predefinedPrompts={predefinedPrompts}
                      handleCurrActivePrompt={setCurrActivePrompt}
                      currActivePrompt={currActivePrompt}
                    />
                  ) : (
                    <ChatResponse />
                  )}
                  <div className="send_msg">
                    <textarea
                      placeholder="Enter Message Here"
                      onChange={msgHandler}
                      defaultValue={
                        activePrompt?.title
                          ? `${activePrompt?.preText} ${activePrompt?.title} ${activePrompt?.postText}`
                          : ""
                      }
                      value={message}
                    />
                    <img
                      src={images?.sendMessage}
                      alt="img"
                      onClick={() => {
                        activePrompt?.title && sendMessage();
                      }}
                      className={activePrompt?.title ? "" : "disable"}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

{
  /* <DateSelect
                      // disabled={true}
                      id={"startDate"}
                      placeholder={"Select Start Date"}
                      val={todoList[0]?.task_start_date}
                      className={"pe-none"}
                    />
                    <DateSelect
                      // disabled={true}
                      placeholder={"Select End Date"}
                      val={todoList[0]?.task_end_date}
                      className={"pe-none"}
                    />*/
}
