import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import { images } from "../../../constants/ImageImports";
import Tooltip from "@mui/material/Tooltip";
import { Link, NavLink } from "react-router-dom";


export const checkPath = (path, arr, val, resVal) => {
  if (arr?.includes(path)) {
    return val;
  } else {
    return resVal;
  }
};

const Sidebar = () => {
  const pathName = window.location.pathname;
  const path = pathName.split("/");
  const [show, setShow] = useState(true);

  /* InterLinks Path */ 
  const student = ['student-list', 'student-edit','student-detail', 'add-student' ]
  const classLink = ['class', 'class-list']

  return (
    <div className={`${show ? styles.sidebar : styles.minSidebar}`}>
      <div className={styles.navBrand}>
       <Link to={'/'}> <img src={show ? images?.logo : images?.minLogo} alt="logo" /></Link>
      </div>
      <div
        className={`${
          show
            ? styles.minimizeBtn
            : styles.minimizeBtn + " " + styles.minimizeBtnCls
        }`}
        onClick={() => setShow(!show)}
      >
        <img src={images?.minimizeIcon} className="w-100" alt="minimize btn" />
      </div>
      <ul className={styles.sidebarNav}>
        <li>
          <NavLink to="/" >
            <span>
              <Tooltip title="Dashboard" placement="bottom-start">
                <img src={images?.homeIcon} alt="home" />
              </Tooltip>
            </span>
            <span className={styles.navtext}>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/class-list" 
            className={checkPath(
              path[1] || pathName,
              classLink,
              "active",
              ""
            )}
          >
            <span>
              <Tooltip title="Classes Management" placement="bottom-start">
                <img src={images?.classManagment} alt="todoIcon" />
              </Tooltip>
            </span>
            <span className={styles.navtext}>Classes Management</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/student-list" 
            className={checkPath(
              path[1] || pathName,
              student,
              "active",
              ""
            )}
          >
            <span>
              <Tooltip title="Student Management" placement="bottom-start">
                <img src={images?.studentManagment} alt="todoIcon" />
              </Tooltip>
            </span>
            <span className={styles.navtext}>Student Management</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/todo-list">
            <span>
              <Tooltip title="To Do Management" placement="bottom-start">
                <img src={images?.toDoIcon} alt="todoIcon" />
              </Tooltip>
            </span>
            <span className={styles.navtext}>To Do 
            List</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/create-todo" className={pathName.includes('/create-todo-assignment') && 'active'}>
            <span>
              <Tooltip title="Create To Do" placement="bottom-start">
                <img src={images?.toDoIcon} alt="todoIcon" />
              </Tooltip>
            </span>
            <span className={styles.navtext}>Create To Do</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/notification">
            <span>
              <Tooltip title="Notification" placement="bottom-start">
                <img src={images?.notificationIcon} alt="Notification" />
              </Tooltip>
            </span>
            <span className={styles.navtext}>Notification</span>
          </NavLink>
        </li>
        <li>
        <NavLink to='setting'>
            <span>
              <Tooltip title="Settings" placement="bottom-start">
                <img src={images?.settingIcon} alt="Settings" />
              </Tooltip>
            </span>
            <span className={styles.navtext}>Settings</span>
          </NavLink>
        </li>
        <li>
          <NavLink to='term-and-conditions'>
            <span>
              <Tooltip title="Terms & Conditions" placement="bottom-start">
                <img src={images?.termIcon} alt="Terms" />
              </Tooltip>
            </span>
            <span className={styles.navtext}>Terms & Conditions</span>
          </NavLink>
        </li>
        <li>
          <NavLink to='privacy-policy'>
            <span>
              <Tooltip title="Privacy Policy" placement="bottom-start">
                <img src={images?.policyIcon} alt="Privacy" />
              </Tooltip>
            </span>
            <span className={styles.navtext}>Privacy Policy</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
