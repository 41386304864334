// import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import modalSlice from './slices/modalSlice';
// import profileSlice from './slices/profileSlice';
// import tempSlice from './slices/tempSlice';
// import studentSlice from './slices/studentSlice';

// const rootReducer = combineReducers({
//   modal: modalSlice.reducer,
//   profile: profileSlice.reducer,
//   temp: tempSlice.reducer,
//   student: studentSlice.reducer
// });

// export const store = configureStore({
//   reducer: rootReducer,
// });



import { combineReducers, createStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import modalSlice from './slices/modalSlice';
import profileSlice from './slices/profileSlice';
import tempSlice from './slices/tempSlice';
import studentSlice from './slices/studentSlice';
import todoSlice from './slices/todoSlice';
import voiceModalSlice from './slices/voiceModalSlice';
import loaderSlice from './slices/loaderSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  modal: modalSlice.reducer,
    profile: profileSlice.reducer,
    temp: tempSlice.reducer,
    student: studentSlice.reducer,
    loaderSlice: loaderSlice.reducer,
    todo: todoSlice.reducer,
    voiceModal: voiceModalSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);  // Use createStore instead of configureStore

const persistor = persistStore(store);

export { store, persistor };

