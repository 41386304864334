import React, { useState } from "react";
import "./taskItem.css";
import { DownloadButton } from "../buttons/DownloadButton";
import { images } from "../../../constants/ImageImports";
import { TaskStepper } from "./TaskStepper";
import { ThemeBtn } from "../buttons/ThemeBtn";
import usePost from "../../../services/customHook/usePost";
import { APIConstant } from "../../../services/apiConstant";
import { tempRefresh } from "../../../redux/slices/tempSlice";
import { useDispatch, useSelector } from "react-redux";
import { mdActions } from "../../../redux/slices/modalSlice";
import Loader from "../loader";
import { DownloadAssBtn } from "../buttons/DownloadAssBtn";

const CheckIcon = ({ isActive }) => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.826172C6.28009 0.826172 0 7.10627 0 14.8262C0 22.5461 6.28009 28.8262 14 28.8262C21.7199 28.8262 28 22.5461 28 14.8262C28 7.10627 21.7199 0.826172 14 0.826172ZM21.0957 11.8594L13.5123 19.4426C13.2848 19.6701 12.9861 19.7846 12.6875 19.7846C12.3889 19.7846 12.0902 19.6701 11.8627 19.4426L8.07111 15.651C7.6148 15.1949 7.6148 14.4575 8.07111 14.0014C8.5272 13.5451 9.26439 13.5451 9.7207 14.0014L12.6875 16.9682L19.4461 10.2098C19.9022 9.75347 20.6394 9.75347 21.0957 10.2098C21.5518 10.6659 21.5518 11.4031 21.0957 11.8594Z"
        fill={isActive ? "#197BBD" : "#9E9E9E"}
      />
    </svg>
  );
};

export const TaskItems = ({ item, isAssignment, allData, studWork }) => {
  const postMutation = usePost();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [inputVisible, setInputVisible] = useState("");
  const [loader, setLoader] = useState(false);
  const [stepper, setStepper] = useState(false);
  const [comment, setComment] = useState("");
  const isRefresh = useSelector((state) => state.temp.isRefresh);

  const handleSubmit = async (item, type, isAssignment, allData) => {
    if (isAssignment) {
      let payloadData = {
        assignment_id: allData?.studentData?.assignment_id,
        student_id: allData?.studentData?.student_id,
      };

      if (type === "rework") {
        payloadData.approved_text = "";
        payloadData.is_approved = false;
        payloadData.is_rework = true;
        payloadData.rework_text = comment;
        payloadData.task_name = allData?.assignment?.assessment_title;
      }
      if (type === "approved") {
        payloadData.is_rework = false;
        payloadData.rework_text = "";
        payloadData.approved_text = comment;
        payloadData.is_approved = true;
        payloadData.task_name = allData?.assignment?.assessment_title;
      }

      if (comment.trim().length > 0) {
        setLoader(true);
        try {
          const result = await postMutation.mutate({
            url: APIConstant?.UPDATE_ASSIGNMENT,
            payload: payloadData,
          });
          setTimeout(() => {
            dispatch(tempRefresh(!isRefresh));
            dispatch(mdActions.hideModal());
            setComment("");
            setInputVisible("");
            setLoader(false);
          }, 1000);
        } catch (error) {
          console.error("Error making profile API call:", error);
        }
      }
    } else {
      let payloadData = {
        status_id: item?.taskStatus?._id,
        student_id: item?.taskStatus?.student_id,
        is_submited: true,
        is_done: false,
      };
      if (type === "rework") {
        payloadData.approved_text = false;
        payloadData.is_approved = "";
        payloadData.is_rework = true;
        payloadData.rework_text = comment;
        payloadData.task_name = item?.task_title;
      }
      if (type === "approved") {
        payloadData.is_rework = false;
        payloadData.rework_text = "";
        payloadData.approved_text = comment;
        payloadData.is_approved = true;
        payloadData.task_name = item?.task_title;
      }
      if (comment.trim().length > 0) {
        setLoader(true);
        try {
          const result = await postMutation.mutate({
            url: APIConstant?.REVIEW_TASK,
            payload: payloadData,
          });
          setTimeout(() => {
            dispatch(tempRefresh(!isRefresh));
            dispatch(mdActions.hideModal());
            setComment("");
            setInputVisible("");
            setLoader(false);
          }, 1000);
        } catch (error) {
          console.error("Error making profile API call:", error);
        }
      }
    }
  };

  const TaskTop = ({
    item,
    show,
    setShow,
    isAssignment,
    allData,
    studWork,
  }) => {
    return (
      <div className="task_item_top">
        <div
          className="task_item_Icon cursor_pointer"
          onClick={() => {
            setShow(!show);
          }}
        >
          {isAssignment ? (
            <img src={images?.pdfIcon} alt="img" />
          ) : (
            <CheckIcon isActive={!!item?.taskStatus?.is_submited} />
          )}
        </div>
        <h5
          className="cursor_pointer"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            setShow(!show);
          }}
        >
          {isAssignment ? item.assessment_title : item.task_title}
        </h5>

        {isAssignment && (
          <div className="task_item_btns">
            {studWork && <DownloadAssBtn item={studWork} />}
            <DownloadButton item={item?.assessment_document} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`task_item ${show ? "show" : ""}`}>
      {loader && <Loader />}
      <TaskTop
        isAssignment={isAssignment}
        item={item}
        show={show}
        setShow={setShow}
        allData={allData}
        studWork={studWork}
      />

      {show && (
        <>
          <div style={{ paddingLeft: "32px" }}>
            <p className="task_ctnt">
              {isAssignment ? item.assessment_description : item.task_content}
            </p>
            <TaskStepper
              itemData={isAssignment ? allData?.studentData : item?.taskStatus}
              isAssignment={isAssignment}
            />
          </div>

          {isAssignment &&
            allData?.studentData?.is_submited === true &&
            (allData?.studentData?.is_done === false ||
              allData?.studentData?.is_done === null ||
              allData?.studentData?.is_done === undefined) && (
              <>
                {inputVisible !== "" && (
                  <div className="text_inpt_wpr">
                    <input
                      type="text"
                      className="cstm_Input"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Enter Your Comment Here.."
                    />
                    <button
                      className="btn_reset download_btn"
                      disabled={comment.trim()?.length === 0}
                      onClick={() =>
                        handleSubmit(item, inputVisible, isAssignment, allData)
                      }
                    >
                      Done
                    </button>
                    <ThemeBtn
                      text={"Cancel"}
                      close={() => setInputVisible("")}
                      closeData={inputVisible}
                      data={stepper}
                    />
                  </div>
                )}

                {(allData?.studentData?.is_submited === true ||
                  allData?.studentData?.is_rework === true) && (
                  <div className="btn_group">
                    <button
                      style={{
                        border:
                          inputVisible === "rework" && "2px solid #3498db",
                      }}
                      className="btn_reset rework_btn"
                      onClick={() => setInputVisible("rework")}
                    >
                      Rework
                    </button>
                    <button
                      style={{
                        border:
                          inputVisible === "approved" && "2px solid #3498db",
                      }}
                      className="btn_reset rework_btn approve_btn"
                      onClick={() => {
                        setInputVisible("approved");
                      }}
                    >
                      Approved By Teacher
                    </button>
                  </div>
                )}
              </>
            )}

          {!isAssignment &&
            item?.taskStatus?.is_submited === true &&
            (item?.taskStatus?.is_done === false ||
              item?.taskStatus?.is_done === null) && (
              <>
                {inputVisible !== "" && (
                  <div className="text_inpt_wpr">
                    <input
                      type="text"
                      className="cstm_Input"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Enter Your Comment Here.."
                    />
                    <button
                      className="btn_reset download_btn"
                      disabled={comment.trim()?.length === 0}
                      onClick={() => handleSubmit(item, inputVisible)}
                    >
                      Done
                    </button>
                    <ThemeBtn
                      text={"Cancel"}
                      close={() => setInputVisible("")}
                      closeData={inputVisible}
                      data={stepper}
                    />
                  </div>
                )}
                {(item?.taskStatus?.is_submited === true ||
                  item?.taskStatus?.is_rework === true) && (
                  <div className="btn_group">
                    <button
                      style={{
                        border:
                          inputVisible === "rework" && "2px solid #3498db",
                      }}
                      className="btn_reset rework_btn"
                      onClick={() => setInputVisible("rework")}
                    >
                      Rework
                    </button>
                    <button
                      style={{
                        border:
                          inputVisible === "approved" && "2px solid #3498db",
                      }}
                      className="btn_reset rework_btn approve_btn"
                      onClick={() => {
                        setInputVisible("approved");
                      }}
                    >
                      Approved By Teacher
                    </button>
                  </div>
                )}
              </>
            )}
        </>
      )}
    </div>
  );
};
