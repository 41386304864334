import React, { useState } from "react";
import moment from "moment";

const DatePicker = ({
  id,
  placeholder,
  handleDateChange,
  disabled,
  selectedDate,
  isMinDate,
  minDate,
}) => {
  return (
    <input
      id={id}
      type="date"
      placeholder={placeholder}
      onChange={handleDateChange}
      min={isMinDate && minDate}
      disabled={disabled}
      value={selectedDate}
      style={{
        cursor: "text",
        width: "46%",
        padding: "10px 20px",
        background: "#f4f4f4",
        borderRadius: "30px",
        fontSize: "14px",
        borderColor: "#0000",
      }}
    />
  );
};
export default DatePicker;
