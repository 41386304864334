import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { TodoList } from "../pages/todoList/TodoList";
import { NotificationPage } from "../pages/notification";
import { Privacy } from "../pages/privacy/Privacy";
import { TermAndCondition } from "../pages/termAndCondition/TermAndCondition";
import { Setting } from "../pages/setting";
import { Login } from "../pages/auth/Login";
import PrivateRoutes from "./PrivateRoutes";
import { ForgotPassword } from "../pages/auth/ForgotPassword";
import { StudentList } from "../pages/student/StudentList";
import { StudentView } from "../pages/student/StudentView";
import { StudentEdit } from "../pages/student/StudentEdit";
import { ClassManagmentList } from "../pages/classManagment/ClassManagmentList";
import { StudentAdd } from "../pages/student/StudentAdd";
import { CreateToDo } from "../pages/createTodo/CreateToDo";
import { CreateToDoAssignment } from "../pages/createTodo/CreateToDoAssignment";
import { ClassStudentList } from "../pages/classManagment/ClassStudentList";
import { ClassStudentView } from "../pages/classManagment/ClassStudentView";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase/firebaseConfig";
import Message from "../components/common/cloudMessage";
import { onMessage } from "firebase/messaging";
import usePost from "../services/customHook/usePost";
import { APIConstant } from "../services/apiConstant";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ResetPassword } from "../pages/auth/ResetPassword";

const Router = () => {
  const { mutateAsync } = usePost();
  const userName = useSelector(
    (state) => state?.profile?.data?.payload?.data?.name
  );

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BKI7kv1oN7BkOSDvCah9eM2mQIBCCx-b2VhhHmG_9-Hw7kI75etEaHvHUGTYy5MXVZ5UCJthFELQFrIEuhKGRvw",
      });
      if (token) {
        try {
          const result = await mutateAsync({
            url: APIConstant?.POST_CIOUD_TOKEN,
            payload: { fmc: token },
            isToastDiasble: true,
          });
          if (result?.name === "AxiosError") {
          }
        } catch (error) {}
      }
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  }

  onMessage(messaging, (payload) => {
    toast.info(<Message notification={payload.notification} />, {
      autoClose: 2000,
    });
  });

  useEffect(() => {
    if (userName) {
      requestPermission();
    }
  }, [userName]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route index element={<Dashboard />} />
          <Route path="notification" element={<NotificationPage />} />
          <Route path="todo-list" element={<TodoList />} />
          <Route path="create-todo" element={<CreateToDo />} />
          <Route
            path="create-todo-assignment"
            element={<CreateToDoAssignment />}
          />
          <Route path="class-list" element={<ClassManagmentList />} />
          <Route path="class/student-list/:id" element={<ClassStudentList />} />
          <Route
            path="class/student-detail/:id"
            element={<ClassStudentView />}
          />
          <Route path="student-list" element={<StudentList />} />
          <Route path="student-detail/:id" element={<StudentView />} />
          <Route path="student-edit/:id" element={<StudentEdit />} />
          <Route path="add-student" element={<StudentAdd />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="setting" element={<Setting />} />
          <Route path="term-and-conditions" element={<TermAndCondition />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
