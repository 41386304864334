import React, { useEffect, useState, Fragment } from "react";
import { TaskItems } from "../../components/common/taskItems/TaskItems";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { APIConstant } from "../../services/apiConstant";
import NoDataFound from "../../components/common/noDataFound";
import usePost from "../../services/customHook/usePost";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

const getDueValue = (inputDate) => {
  const formattedDate = moment(inputDate).format("D MMM YYYY");
  const endDateAfter7Days = moment(formattedDate)
    .add(6, "days")
    .format("D MMM YYYY");
  return `Due to ${formattedDate} to ${endDateAfter7Days}`;
};

const WithTodoListHoc = (WrappedComponent) => {
  const NewWrappedComponent = (props) => {
    const { mutateAsync } = usePost();
    const dispatch = useDispatch();
    const isRefresh = useSelector((state) => state.temp.isRefresh);
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [apiType, setApiType] = useState(null);
    const [studentId, setStudentId] = useState([]);

    const handlePost = async (item) => {
      setLoader(true);
      try {
        const { payload } = await mutateAsync({
          url: APIConstant?.TODO_LIST_BY_CATEGORY,
          payload: {
            todo_type: apiType,
            page: 1,
            pageSize: 11,
            student_id: props?.studentIDData,
          },
          isToastDiasble: true,
        });
        setLoader(false);
        setData(payload);
      } catch (error) {}
    };

    useEffect(() => {
      if (apiType !== null) {
        dispatch(toggleLoader({ loader: true }));
        handlePost();
      }
    }, [props?.studentIDData, isRefresh, apiType]);

    return (
      <>
        <div className="todo_list">
          {data?.data?.length > 0 &&
            data?.data?.map((item, i, arr) => {
              let studWork = item?.studentData?.assignmentFile;
              return (
                <Fragment key={i}>
                  <p className="due_Date">
                    {getDueValue(item?.task_start_date, item?.task_end_date)}
                  </p>
                  {item?.tasks?.length > 0 &&
                    item?.tasks?.map((taskobj, index, taskarr) => {
                      return (
                        <TaskItems
                          item={taskobj}
                          key={i}
                          allData={item}
                          studWork={studWork}
                        />
                      );
                    })}

                  {item?.assignment?.assessment_document && (
                    <TaskItems
                      item={item?.assignment}
                      isAssignment={true}
                      allData={item}
                      studWork={studWork}
                    />
                  )}
                </Fragment>
              );
            })}
        </div>
        <NoDataFound tableData={data} loader={loader} />
        <WrappedComponent
          {...props}
          setApiType={setApiType}
          setStudentId={setStudentId}
        />
      </>
    );
  };

  return NewWrappedComponent;
};

export default WithTodoListHoc;
